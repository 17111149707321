import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { SlideshowLightbox } from 'lightbox.js-react';
import { useAppDispatch } from '../../store';
import {
  fetchAllOffers,
  fetchEntConfigs,
  fetchGenieCardList, fetchKycConfigs,
  fetchPartnerDetails,
  fetchPaymentMsisdnList,
  fetchSingleApp,
  fetchSliderItems,
  fetchTenantMasks,
  onRedirectTo,
  submitAppSubscription,
  submitBetterHrSubscription,
  submitClick2PaySubscription,
  submitKYCWidgetSubscription,
  submitWorkhubSubscription,
  subscribeClicked,
  supportNavigation
} from '../../store/actions';
import { AppWizardModel } from '../discover/models/response/app-wizard.model';
import { RatePlanModel } from './models/response/rate-plan.model';
import { SimilarApps, SingleAppModel, SubWidgets, WidgetFeatures, WidgetRate, WidgetScreenshots } from './models/response/single-app.model';
import { AppDetails } from './components/AppDetails';
import { AppSubscription } from './components/AppSubscription';
import { GenieCardListModel } from './models/response/genie-card-list.model';
import { PaymentMsisdnListModel } from './models/response/payment-msisdn-list.model';
import SimpleReactValidator from 'simple-react-validator';
import { saveWorkHubSubscribe } from '../../services/widgets.service';
import * as Constants from '../../constants';
import {message, notification} from "antd";
import { off } from 'process';

type Props = {}

const SingleApp = (props: Props) => {
  const { wid } = useParams();
  const [step, setStep] = useState<number>(1);
  const [paymentNumber, setPaymentNumber] = useState<PaymentMsisdnListModel | null>(null);
  const [paymentCard, setPaymentCard] = useState<GenieCardListModel | null>(null);
  const [selectedRate, setSelectedRate] = useState<string>('');
  const [selectedSubWidget, setSelectedSubWidget] = useState<SubWidgets | null>(null);
  const [agent, setAgent] = useState<string>('');
  const [displayMsisdnModal, setDisplayMsisdnModal] = useState<boolean>(false);
  const [displayCardModal, setDisplayCardModal] = useState<boolean>(false);
  const [maxWidgetCount, setMaxWidgetCount] = useState<number>(0);
  const [usageCount, setUsageCount] = useState<string>("");
  const [subscriptionRate, setSubscriptionRate] = useState(0.00);
  const [loading, setLoading] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState("add_to_bill")
  const [tenantMasks, setTenantMasks] = useState<any[]>([])
  const [showConsentModal, setShowConsentModal] = useState<boolean>(false);
  const [isConsentTrue, setIsConsentTrue] = useState<boolean>(false);
  const [tncChecked, setTncChecked] = useState<boolean>(false);
  const [tncError, setTncError] = useState<string>("");

  const [validator] = useState(new SimpleReactValidator({
    messages: {
      regex: 'The :attribute field must have atleast two words (First name and last name).'
    },
    autoForceUpdate: this
  }))
  const dispatch = useAppDispatch();

  const [appDetails, setAppDetails] = useState<SingleAppModel | null>(null)
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(true);
  // const [sliderItems, setSliderItems] = useState<Array<any> | null>(null)
  const [isNeedPort, setIsNeedPort] = useState<boolean | undefined>(false);
  const [isMaskRequired, setIsMaskRequired] = useState<boolean | undefined>(false);
  const [isNeedLongNumber, setIsNeedLongNumber] = useState<boolean | undefined>(false);

  const {
    totAmountData,
    licenceCount
  } = useSelector<any, any>(({ betterhr }) => betterhr);

  const {
    allOffersList
} = useSelector<any, any>(({ offers }) => offers);

  const navigate = useNavigate();

  const useForceUpdate = () => {
    const [value, setValue] = useState(0);
    return () => setValue(value => value + 1);
  }

  const forceUpdate = useForceUpdate()
  const {
    singleAppDetails,
    sliderItemsList
  } = useSelector<any, any>(({ apps }) => apps);

  const {
    partnerDetails
  } = useSelector<any, any>(({ settings }) => settings);

  const {
    paymentMsisdnList,
    genieCardList,
    appSubLoading
  } = useSelector<any, any>(({ appWizard }) => appWizard);

  const {
    tenantMaskList,
    entConfigs
  } = useSelector<any, any>(({ ent }) => ent);

  const { userDetails } = useSelector<any, any>(({ user }) => user);

  const {
    authData
  } = useSelector<any, any>(({ authentication }) => authentication);

  const {
    kycConfigs
  } = useSelector(({ kyc }) => kyc);

  useEffect(() => {
    const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "0";
    dispatch(fetchSingleApp(wid!, tenantGroupId));
    if (tenantGroupId !== "") {
      dispatch(fetchPaymentMsisdnList(tenantGroupId, wid!));
      dispatch(fetchGenieCardList(tenantGroupId));
      dispatch<any>(fetchPartnerDetails(tenantGroupId));
    }
  }, [userDetails, wid])

  useEffect(() => {
    if (singleAppDetails) {
      setAppDetails(singleAppDetails)
      if (singleAppDetails && singleAppDetails.isSubscriptionActive === false) {
        setIsSubscriptionActive(false);
      }
    }
  }, [singleAppDetails, wid])

  useEffect(() => {
    const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
    if (tenantGroupId !== "") {
      dispatch(fetchTenantMasks(tenantGroupId));
      dispatch(fetchEntConfigs(tenantGroupId));
    }
  }, [userDetails])

  useEffect(() => {
    if (Array.isArray(tenantMaskList) && tenantMaskList.length > 0) {
      let tenantMasksArray = tenantMaskList.filter(item => item.maskType == `USER` && item.status == `Active`);
      setTenantMasks(tenantMasksArray)
    }
  }, [tenantMaskList])

  useEffect(() => {

    setIsNeedPort(appDetails?.widgetDetails?.isNeedPort);
    setIsMaskRequired(appDetails?.widgetDetails?.isMaskRequired);
    setIsNeedLongNumber(appDetails?.widgetDetails?.isNeedLongNumber);

  }, [appDetails])

  const onBack = () => {
    navigate(-1);
  }

  const onSubscribe = () => {
    dispatch(subscribeClicked())
    if (wid === Constants.BETTER_HR_WIDGET_ID) {
      if (licenceCount && licenceCount >= 10 && totAmountData && totAmountData.totalAmount >= 0) {
        setStep(step + 1);
      } else {
        if (licenceCount && licenceCount < 10) {
          message.error("Please enter a valid license count.");
        } else {
          message.error("Please enter the license count.");
        }
        // @ts-ignore
        document.getElementById('pricing').scrollIntoView()
      }
    } else {
      setStep(step + 1);
    }
    if (appDetails && appDetails.widgetDetails && appDetails.widgetDetails.chargeableType === 7) {
      const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
      if (tenantGroupId) {
        dispatch(fetchKycConfigs(tenantGroupId, wid!));
      }
    }
  }

  const changePaymentMethod = (name: string) => (event: any) => {
    setPaymentMethod(event.target.value);
  };

  const onPressAddMask = async () => {
    navigate(`/settings/number-configures`)
  }

  const checkIsSubscribable = () => {

    if (isNeedPort || isMaskRequired || isNeedLongNumber) {
      if ((isNeedPort && isMaskRequired && isNeedLongNumber) || (isNeedPort && !isMaskRequired && isNeedLongNumber)) {

        if (entConfigs && entConfigs.requestport && entConfigs.requestLongNumber) {
          return true;
        } else if (entConfigs && entConfigs.requestport && !entConfigs.requestLongNumber) {
          notification.warning({
            message: `Warning`,
            description: <div className="toatify_warning">Note: Please ensure to enter a confirmed long number, If not your customers will be charged for survey SMS<br />
              <button className="btn btn-sm" onClick={onPressAddMask}>Add Long Number</button>
            </div>,
            placement: 'topRight'
          });
        } else if (entConfigs && !entConfigs.requestport && entConfigs.requestLongNumber) {
          notification.warning({
            message: `Warning`,
            description: <div className="toatify_warning">You need to request the port for this service<br />
              <button className="btn btn-sm" onClick={onPressAddMask}>Add port</button>
            </div>,
            placement: 'topRight'
          });
        } else if (entConfigs && !entConfigs.requestport && !entConfigs.requestLongNumber) {
          notification.warning({
            message: `Warning`,
            description: <div className="toatify_warning">You need to configure Port and Long Number for this service<br />
              <button className="btn btn-sm" onClick={onPressAddMask}>Add port and Long Number</button>
            </div>,
            placement: 'topRight'
          });
        } else {
          notification.warning({
            message: `Warning`,
            description: <div className="toatify_warning">Something Went Wrong Please try Again<br />
            </div>,
            placement: 'topRight'
          });
        }
      }

      if (isNeedPort && !isMaskRequired && !isNeedLongNumber) {
        if (entConfigs && entConfigs.requestport) {
          return true;
        } else {
          notification.warning({
            message: `Warning`,
            description: <div className="toatify_warning">You need to request the port for this service<br />
              <button className="btn btn-sm" onClick={onPressAddMask}>Add port</button>
            </div>,
            placement: 'topRight'
          });
        }
      }

      if (isNeedPort && isMaskRequired && !isNeedLongNumber) {
        if (entConfigs && entConfigs.requestport && tenantMasks.length > 0) {
          return true;
        } else if (entConfigs && !entConfigs.requestport && tenantMasks.length > 0) {
          notification.warning({
            message: `Warning`,
            description: <div className="toatify_warning">You need to request the port for this service<br />
              <button className="btn btn-sm" onClick={onPressAddMask}>Add port</button>
            </div>,
            placement: 'topRight'
          });
        } else if (entConfigs && entConfigs.requestport && !(tenantMasks.length > 0)) {
          notification.warning({
            message: `Warning`,
            description: <div className="toatify_warning">You need to request a mask for this service<br />
              <button className="btn btn-sm" onClick={onPressAddMask}>Add mask</button>
            </div>,
            placement: 'topRight'
          });
        } else {
          notification.warning({
            message: `Warning`,
            description: <div className="toatify_warning">You need to request a port and a mask for this service<br />
              <button className="btn btn-sm" onClick={onPressAddMask}>Add port</button>
            </div>,
            placement: 'topRight'
          });
        }
      }

      if (!isNeedPort && isMaskRequired && isNeedLongNumber) {
        if (entConfigs && (tenantMasks.length > 0) && entConfigs.requestLongNumber) {
          return true;
        } else if (entConfigs && (tenantMasks.length > 0) && !entConfigs.requestLongNumber) {
          notification.warning({
            message: `Warning`,
            description: <div className="toatify_warning">Note: Please ensure to enter a confirmed long number, If not your customers will be charged for survey SMS<br />
              <button className="btn btn-sm" onClick={onPressAddMask}>Add Long Number </button>
            </div>,
            placement: 'topRight'
          });
        } else if (entConfigs && !(tenantMasks.length > 0) && entConfigs.requestLongNumber) {
          notification.warning({
            message: `Warning`,
            description: <div className="toatify_warning">Mask is mandatory for this widget<br />
              <button className="btn btn-sm" onClick={onPressAddMask}>Add Mask</button>
            </div>,
            placement: 'topRight'
          });
        } else if (entConfigs && !(tenantMasks.length > 0) && !entConfigs.requestLongNumber) {
          notification.warning({
            message: `Warning`,
            description: <div className="toatify_warning">You need to configure Mask and Long Number for this service <br />
              <button className="btn btn-sm" onClick={onPressAddMask}>Add Mask and Long Number </button>
            </div>,
            placement: 'topRight'
          });
        } else {
          notification.warning({
            message: `Warning`,
            description: <div className="toatify_warning">Something Went Wrong Please try Again<br />
            </div>,
            placement: 'topRight'
          });
        }
      }
      if (!isNeedPort && isMaskRequired && !isNeedLongNumber) {
        if (entConfigs && (tenantMasks.length > 0)) {
          return true;
        } else {
          notification.warning({
            message: `Warning`,
            description: <div className="toatify_warning">Mask is mandatory for this widget<br />
              <button className="btn btn-sm" onClick={onPressAddMask}>Add Mask</button>
            </div>,
            placement: 'topRight'
          });
        }
      }
      if (!isNeedPort && !isMaskRequired && isNeedLongNumber) {
        if (entConfigs && entConfigs.requestLongNumber) {
          return true;
        } else {
          notification.warning({
            message: `Warning`,
            description: <div className="toatify_warning">Note: Please ensure to enter a confirmed long number, If not your customers will be charged for survey SMS<br />
              <button className="btn btn-sm" onClick={onPressAddMask}>Add Long Number</button>
            </div>,
            placement: 'topRight'
          });
        }
      }
    } else if (appDetails?.widgetDetails.chargeableType == 13 && (selectedRate == '' || selectedRate == null)) {
      notification.warning({
        message: `Warning`,
        description: <div className="toatify_warning">Selecting a package is mandatory for this widget.Please select a package.<br />
        </div>,
        placement: 'topRight'
      });
      return false
    } else {
      return true;
    }

    // if (appDetails && appDetails.widgetDetails) {
    //   if (appDetails.widgetDetails.isMaskRequired) {
    //     if (entConfigs && tenantMasks.length > 0) {
    //       return true
    //     } else {
    //       notification.warning({
    //         message: `Warning`,
    //         description: <div className="toatify_warning">Mask is mandatory for this widget<br />
    //           <button className="btn btn-sm" onClick={onPressAddMask}>Add Mask</button>
    //         </div>,
    //         placement: 'topRight'
    //       });
    //       return false
    //     }
    //   } else if (appDetails && appDetails.widgetDetails.isNeedPort && appDetails.widgetDetails.isMaskRequired) {
    //     if (entConfigs && entConfigs.requestport && tenantMasks.length > 0) {
    //       return true;
    //     } else if (entConfigs && !entConfigs.requestport && tenantMasks.length > 0) {
    //       notification.warning({
    //         message: `Warning`,
    //         description: <div className="toatify_warning">You need to request the port for this service<br />
    //           <button className="btn btn-sm" onClick={onPressAddMask}>Add Port</button>
    //         </div>,
    //         placement: 'topRight'
    //       });
    //       return false
    //     }
    //   } else if (appDetails.widgetDetails.chargeableType == 13 && (selectedRate == '' || selectedRate == null)) {
    //     notification.warning({
    //       message: `Warning`,
    //       description: <div className="toatify_warning">Selecting a package is mandatory for this widget! Please select a package<br />
    //       </div>,
    //       placement: 'topRight'
    //     });
    //     return false
    //   } else {
    //     return true
    //   }
    // } else {
    //   return false
    // }
  }

  const checkTnC = () => {
    if(appDetails) {
      if(appDetails.widgetDetails.shouldAcceptTnC && !tncChecked) {
        setTncError("Should accept terms and conditions.");
        return false;
      } else {
        return true;
      }
    }
  }

  const onAppSub = () => {
    if (validator.allValid() && checkIsSubscribable() && checkTnC()) {
      const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
      const billingStatus = (userDetails && userDetails.OwnedEnterprises[0] && userDetails.OwnedEnterprises[0].Enterprise && userDetails.OwnedEnterprises[0].Enterprise.billingStatus) ? userDetails.OwnedEnterprises[0].Enterprise.billingStatus : "";

      let ratePlanList = [];
      let setupfeeUUID;
      let RentelUUID;
      let usageUUID;
      let chargeableType = appDetails ? appDetails.widgetDetails.chargeableType : 0;
      if (chargeableType == 1) {
        ratePlanList = [
          selectedRate
        ];
      } else if (chargeableType == 2) {
        const usage = JSON.parse(usageCount);
        ratePlanList.push(usage.uuid);

        const body = {
          widgetCount: maxWidgetCount,
          subscriptionRate: subscriptionRate,
          ratePlans: ratePlanList,
          paymentMethodId: billingStatus == "Non_invoice" ? (paymentMethod == "add_to_bill" ? 1 : 9) : 4,
          msisdn: billingStatus == "Non_invoice" ? (paymentMethod == "add_to_bill" ? (paymentNumber ? paymentNumber.msisdn : null) : null) : null,
          invoiceNo: billingStatus == "Non_invoice" ? (paymentMethod == "add_to_bill" ? null : (paymentCard ? paymentCard.invoiceNo : null)) : null,
          maId: agent
        }
        dispatch(submitWorkhubSubscription(tenantGroupId, wid!, body, navigate, appDetails));

      } else if (chargeableType == 3) {

        appDetails && appDetails.widgetDetails && Array.isArray(appDetails.widgetDetails.WidgetRate) && appDetails.widgetDetails.WidgetRate.map((value: WidgetRate, index: number) => {
          if (value.RateCard.name === 'Setup fee') {
            setupfeeUUID = value.uuid;
          }
          if (value.RateCard.name === 'Rental' || value.RateCard.name === 'Monthly charge') {
            RentelUUID = value.uuid;
          }
          if (value.RateCard.name === 'Usage') {
            usageUUID = value.uuid;
          }
        });

        ratePlanList.push(setupfeeUUID);
        ratePlanList.push(RentelUUID);
        ratePlanList.push(usageUUID);
      } else if (chargeableType == 4) {
        const body = {
          paymentMethodId: billingStatus == "Non_invoice" ? (paymentMethod == "add_to_bill" ? 1 : 9) : 4,
          msisdn: billingStatus == "Non_invoice" ? (paymentMethod == "add_to_bill" ? (paymentNumber ? paymentNumber.msisdn : null) : null) : null,
          invoiceNo: billingStatus == "Non_invoice" ? (paymentMethod == "add_to_bill" ? null : (paymentCard ? paymentCard.invoiceNo : null)) : null,
          maId: agent
        }
        dispatch(submitClick2PaySubscription(tenantGroupId, wid!, body, navigate, appDetails));
      } else if (chargeableType == 5) {
        appDetails && appDetails.widgetDetails && Array.isArray(appDetails.widgetDetails.WidgetRate) && appDetails.widgetDetails.WidgetRate.map((value: WidgetRate, index: number) => {
          if (value.RateCard.name === 'Setup fee') {
            setupfeeUUID = value.uuid;
          }
          if (value.RateCard.name === 'Rental' || value.RateCard.name === 'Monthly charge') {
            RentelUUID = value.uuid;
          }
          if (value.RateCard.name === 'Usage') {
            usageUUID = value.uuid;
          }
        });
        ratePlanList.push(setupfeeUUID);
        ratePlanList.push(RentelUUID);
        ratePlanList.push(usageUUID);
      } else if (chargeableType == 6) {
        ratePlanList = [
          selectedRate
        ];
      } else if (chargeableType == 7) {
        if (kycConfigs && Object.keys(kycConfigs).length == 0) {
          setShowConsentModal(true);
        } else if (kycConfigs && Object.keys(kycConfigs).length >= 0 && appDetails && appDetails.widgetDetails && (appDetails.widgetDetails.groupId == 2 || appDetails.widgetDetails.groupId == 3 ) && (kycConfigs.groupId != 2 && kycConfigs.groupId != 3 && kycConfigs.groupId != null)) {
            setShowConsentModal(true);
        } else if (kycConfigs && Object.keys(kycConfigs).length >= 0 && appDetails && appDetails.widgetDetails && (appDetails.widgetDetails.groupId != 2 && appDetails.widgetDetails.groupId != 3 ) && (appDetails.widgetDetails.groupId != kycConfigs.groupId)) {
            setShowConsentModal(true);
        } else {
          appDetails && appDetails.widgetDetails && Array.isArray(appDetails.widgetDetails.WidgetRate) && appDetails.widgetDetails.WidgetRate.map((value: WidgetRate, index: number) => {
            if (value.RateCard.name === 'Setup fee') {
              setupfeeUUID = value.uuid;
            }
            if (value.RateCard.name === 'Rental' || value.RateCard.name === 'Monthly charge') {
              RentelUUID = value.uuid;
            }
            if (value.RateCard.name === 'Usage') {
              usageUUID = value.uuid;
            }
          });
          ratePlanList.push(setupfeeUUID);
          ratePlanList.push(RentelUUID);
          ratePlanList.push(usageUUID);

          const offer = singleAppDetails?.offerDetails && singleAppDetails?.offerDetails.offerList.find((offer: { serviceId: any; }) => offer.serviceId === singleAppDetails.widgetDetails.uuid);
          // const offer = allOffersList && Array.isArray(allOffersList?.allOffers?.offerList) && allOffersList?.allOffers?.offerList.find((offer: { serviceId: string; }) => offer.serviceId === appDetails?.widgetDetails?.uuid);
          // console.log("offer ", offer)

          let req = {
            "ratePlans": ratePlanList ? ratePlanList : [],
            "status": "Subscription",
            "paymentMethodId": billingStatus == "Non_invoice" ? (paymentMethod == "add_to_bill" ? 1 : 9) : 4,
            "msisdn": billingStatus == "Non_invoice" ? (paymentMethod == "add_to_bill" ? (paymentNumber ? paymentNumber.msisdn : null) : null) : null,
            "invoiceNo": billingStatus == "Non_invoice" ? (paymentMethod == "add_to_bill" ? null : (paymentCard ? paymentCard.invoiceNo : null)) : null,
            "maId": agent,
            "offerId": offer ? offer.id : ""
          }
          dispatch(submitAppSubscription(tenantGroupId, wid!, req, navigate, appDetails));
        }
      } else if (chargeableType == 10) {
        if (totAmountData && licenceCount) {
          const body = {
            "count": licenceCount,
            "ratePlans": totAmountData.rateCardDetails,
            "status": "Subscription",
            "paymentMethodId": billingStatus == "Non_invoice" ? (paymentMethod == "add_to_bill" ? 1 : 9) : 4,
            "msisdn": billingStatus == "Non_invoice" ? (paymentMethod == "add_to_bill" ? (paymentNumber ? paymentNumber.msisdn : null) : null) : null,
            "invoiceNo": billingStatus == "Non_invoice" ? (paymentMethod == "add_to_bill" ? null : (paymentCard ? paymentCard.invoiceNo : null)) : null,
            "maId": agent
          }
          dispatch(submitBetterHrSubscription(tenantGroupId, wid!, body, navigate, appDetails));
        }
      } else if (chargeableType == 11) {
        // if(appDetails && appDetails.widgetDetails && appDetails.widgetDetails.signupUrl) {
        //   navigate(`${appDetails.widgetDetails.signupUrl}/${wid}/create`);
        // }
        ratePlanList = [
          selectedRate
        ];
      } else if (chargeableType == 13) {
        ratePlanList = [
          selectedRate
        ];
      } else {
        selectedSubWidget?.SubWidgetRate?.forEach((value: WidgetRate) => {
          if (value.RateCard.name === 'Setup fee') {
            setupfeeUUID = value.uuid;
          }
          if (value.RateCard.name === 'Rental' || value.RateCard.name === 'Monthly charge') {
            RentelUUID = value.uuid;
          }
          if (value.RateCard.name === 'Usage') {
            usageUUID = value.uuid;
          }
        });

        ratePlanList.push(setupfeeUUID);
        ratePlanList.push(RentelUUID);
        ratePlanList.push(usageUUID);
      }
      if (chargeableType !== 2 && chargeableType !== 4 && chargeableType !== 7 && chargeableType !== 10) {
        const offer = singleAppDetails?.offerDetails && singleAppDetails?.offerDetails.offerList.find((offer: { serviceId: any; }) => offer.serviceId === singleAppDetails.widgetDetails.uuid);
        // const offer = allOffersList && Array.isArray(allOffersList?.allOffers?.offerList) && allOffersList?.allOffers?.offerList.find((offer: { serviceId: string; }) => offer.serviceId === appDetails?.widgetDetails?.uuid);

        console.log("offer ", offer)

        let req = {
          "ratePlans": ratePlanList,
          "status": "Subscription",
          "paymentMethodId": billingStatus == "Non_invoice" ? (paymentMethod == "add_to_bill" ? 1 : 9) : 4,
          "msisdn": billingStatus == "Non_invoice" ? (paymentMethod == "add_to_bill" ? (paymentNumber ? paymentNumber.msisdn : null) : null) : null,
          "invoiceNo": billingStatus == "Non_invoice" ? (paymentMethod == "add_to_bill" ? null : (paymentCard ? paymentCard.invoiceNo : null)) : null,
          "maId": agent,
          "offerId": offer ? offer.id : ""
        }

        dispatch(submitAppSubscription(tenantGroupId, wid!, req, navigate, appDetails));
      }
    } else {
      validator.showMessages();
      forceUpdate();
    }
  }

  const onChangeRate = (e: any) => {
    // console.log("sdsd", e.target.value);

    setSelectedRate(e.target.value);
  }

  const onChangeAgent = (e: any) => {
    setAgent(e.target.value);
  }

  const onChangeTnC = (e: any) => {
    setTncError("");
    setTncChecked(JSON.parse(e.target.checked));
  }

  useEffect(() => {
    if (usageCount) {
      const usage = JSON.parse(usageCount);
      if (usage && maxWidgetCount) {
        setSubscriptionRate(usage.rate * maxWidgetCount);
      }
    }
  }, [usageCount, maxWidgetCount]);

  const onChangeWidgetCount = (value: any, appDetails: SingleAppModel) => {
    let widgetCount = appDetails.isGroupCategory && appDetails.groupDetails && appDetails.groupDetails[0].widgetCount;
    setMaxWidgetCount(value <= widgetCount ? value : widgetCount);
  }

  const onChangeUsageCount = (e: any) => {
    setUsageCount(e.target.value);
  }

  const onNavigateTo = () => {
    const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
    if (isSubscriptionActive) {
      onRedirectTo(tenantGroupId, navigate, appDetails?.widgetDetails, dispatch, null);
    } else {
      notification.warning({
        message: `Widget is Disabled`,
        description: <p>Pending annual subscription renewal. Renew your subscription via <b>{'Settings –> Billing & Plans'}</b></p>,
        placement: 'topRight'
      });
    }
  }

  const onChangeWidget = (e: any) => {
    setSelectedSubWidget(JSON.parse(e.target.value));
  }

  const onConsentStatusChange = async (val: any) => {
    console.log('onConsentStatusChange val = ', val);
    setIsConsentTrue(val)
  }

  const closeModalEntConsentModal = async () => {
    setShowConsentModal(false);
  }

  const addEntConsentDetails = async (formData: any) => {
    let consentReq = {
      consentStatus: formData.consentStatus === true ? 'yes' : 'no',
      consentMedium: formData.consentMedium
    }

    const tenantGroupId = (userDetails && userDetails.OwnedEnterprises[0]) ? userDetails.OwnedEnterprises[0].groupId : "";
    const billingStatus = (userDetails && userDetails.OwnedEnterprises[0] && userDetails.OwnedEnterprises[0].Enterprise && userDetails.OwnedEnterprises[0].Enterprise.billingStatus) ? userDetails.OwnedEnterprises[0].Enterprise.billingStatus : "";
    let setupfeeUUID;
    let RentelUUID;
    let usageUUID;
    let ratePlanList = [];
    appDetails && appDetails.widgetDetails && Array.isArray(appDetails.widgetDetails.WidgetRate) && appDetails.widgetDetails.WidgetRate.map((value: WidgetRate, index: number) => {
      if (value.RateCard.name === 'Setup fee') {
        setupfeeUUID = value.uuid;
      }
      if (value.RateCard.name === 'Rental' || value.RateCard.name === 'Monthly charge') {
        RentelUUID = value.uuid;
      }
      if (value.RateCard.name === 'Usage') {
        usageUUID = value.uuid;
      }
    });
    ratePlanList.push(setupfeeUUID);
    ratePlanList.push(RentelUUID);
    ratePlanList.push(usageUUID);
    let subscriptionReq = {
      "ratePlans": ratePlanList ? ratePlanList : [],
      "status": "Subscription",
      "paymentMethodId": billingStatus == "Non_invoice" ? (paymentMethod == "add_to_bill" ? 1 : 9) : 4,
      "msisdn": billingStatus == "Non_invoice" ? (paymentMethod == "add_to_bill" ? (paymentNumber ? paymentNumber.msisdn : null) : null) : null,
      "invoiceNo": billingStatus == "Non_invoice" ? (paymentMethod == "add_to_bill" ? null : (paymentCard ? paymentCard.invoiceNo : null)) : null,
      "maId": agent
    }
    let req = {
      consent: consentReq,
      subcription: subscriptionReq
    }
    dispatch(submitKYCWidgetSubscription(tenantGroupId, wid!, req, navigate, appDetails));
    setShowConsentModal(false);
  }

  let campaignUuid = userDetails ? window.location.pathname.split('/')[4] : window.location.pathname.split('/')[3];

  useEffect(() => {
    let route = window.location.pathname.split('/')[1];
    console.log("campaignUuid", campaignUuid);

    if (route == 'widget' && authData) {
      if(campaignUuid != '' && campaignUuid != null && campaignUuid != undefined) {
        navigate(`/discover/widget/${wid}/${campaignUuid}`);
    } else {
        navigate(`/discover/widget/${wid}`);
    }    }
  }, []);

    useEffect(() => {
      const tenantGroupId = (userDetails && userDetails?.OwnedEnterprises[0]) ? userDetails?.OwnedEnterprises[0].groupId : "";
      if(userDetails) {
        dispatch(fetchAllOffers(userDetails?.uuid, tenantGroupId));
      } else {
        dispatch(fetchAllOffers("0", "0"));
      }
  }, [userDetails]);

  const getOfferDescription = () => {
    const offer = allOffersList && Array.isArray(allOffersList?.allOffers?.offerList) && allOffersList?.allOffers?.offerList.find((offer: { serviceId: string; }) => offer.serviceId === (appDetails && appDetails?.widgetDetails?.uuid));
    if(offer) {
      return offer.description;
    }
  };
  
  const offerDescription = getOfferDescription();

  const offer = allOffersList && Array.isArray(allOffersList?.allOffers?.offerList) && allOffersList?.allOffers?.offerList.find((offer: { serviceId: string; }) => offer.serviceId === appDetails?.widgetDetails?.uuid);
  
  return (
    step === 1 ? <AppDetails
      wid={wid}
      campaignUuid={campaignUuid}
      appDetails={appDetails}
      userDetails={userDetails}
      onBack={onBack}
      onSubscribe={onSubscribe}
      onNavigateTo={onNavigateTo}
      offerDescription={offerDescription ? offerDescription : ""}
      offer={offer}
      getOfferDescription={getOfferDescription}
    /> : <AppSubscription
      appDetails={appDetails}
      changePaymentMethod={changePaymentMethod}
      loading={loading}
      setLoading={setLoading}
      paymentMethod={paymentMethod}
      userDetails={userDetails}
      paymentMsisdnList={paymentMsisdnList}
      genieCardList={genieCardList}
      paymentNumber={paymentNumber}
      setPaymentNumber={setPaymentNumber}
      paymentCard={paymentCard}
      setPaymentCard={setPaymentCard}
      setStep={setStep}
      step={step}
      onAppSub={onAppSub}
      onChangeRate={onChangeRate}
      selectedRate={selectedRate}
      partnerDetails={partnerDetails}
      onChangeAgent={onChangeAgent}
      agent={agent}
      setDisplayMsisdnModal={setDisplayMsisdnModal}
      displayMsisdnModal={displayMsisdnModal}
      setDisplayCardModal={setDisplayCardModal}
      displayCardModal={displayCardModal}
      validator={validator}
      appSubLoading={appSubLoading}
      onChangeWidgetCount={onChangeWidgetCount}
      maxWidgetCount={maxWidgetCount}
      onChangeUsageCount={onChangeUsageCount}
      usageCount={usageCount}
      subscriptionRate={subscriptionRate}
      onChangeWidget={onChangeWidget}
      selectedSubWidget={selectedSubWidget}
      showConsentModal={showConsentModal}
      onConsentStatusChange={onConsentStatusChange}
      isConsentTrue={isConsentTrue}
      closeModalEntConsentModal={closeModalEntConsentModal}
      addEntConsentDetails={addEntConsentDetails}
      tncChecked={tncChecked}
      onChangeTnC={onChangeTnC}
      tncError={tncError}
    />
  )
}

export default SingleApp;